import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    IcommissioningData,
    IcommissioningDataPayload,
    IinstallBase,
    Ijob,
    IjobWorkOrder,
    Ioption,
    Iproduct,
    ItableFiltersParams,
    IWorkOrder,
    JobNewInstallBases
} from '../models';
import { filter, forEach, keyBy, map, mapValues, omit, pickBy, values } from 'lodash';

import API from '../constants/apiEndpoints';
import { IinitialState } from '../reducers';
import { OfflineProductsProvider } from '../store/offlineProductsProvider';
import { TFunction } from 'i18next';
import { ThunkAction } from 'redux-thunk';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { appSyncItemNames, constants } from '../constants/constants';
import { initialProduct, initialWorkOrder } from '../reducers/initialState';
import { msalFetch } from '../components/auth/Auth-Utils';
import { selectMainCategoryFromProductID } from '../reducers/manageInventoryReducer';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { AppSyncItemStatus, beaconContactTypeEnum, jobTypesIdEnum, workOrderPrioritiesEnum } from '../models-enums';
import { selectJobWorkOrdersForJobID } from '../reducers/commonSelectors';
import { getLatestHelper } from './commonActions';
import { getWorkOrders } from './workOrderActions';
import { FieldConfig } from 'react-reactive-form';
import { selectSelectedInstallBaseIDs } from '../reducers/commonReducers';
import { FormUtil } from '../components/common/FormUtil';
import localforage from 'localforage';

const uuidv4 = require('uuid/v4');

type ThunkResult<R> = ThunkAction<R, IinitialState, undefined, any>;

const offlineProductsProvider = new OfflineProductsProvider();

const cleanQuantity = (quantity: any): number => {
    // Make sure quantity is a number
    if (quantity === undefined || quantity === null || Number.isNaN(quantity)) {
        return 1;
    }

    if (typeof quantity === 'string') {
        return parseInt(quantity);
    }

    return quantity;
};

const cleanInstallBase = (installBase: IinstallBase): {} => ({
    ...installBase,
    productID: installBase.productID && installBase.productID !== '' ? installBase.productID : null,
    facilityID: installBase.facilityID && installBase.facilityID !== '' ? installBase.facilityID : null,
    buildingID: installBase.buildingID && installBase.buildingID !== '' ? installBase.buildingID : null,
    floorID: installBase.floorID && installBase.floorID !== '' ? installBase.floorID : null,
    locationID: installBase.locationID && installBase.locationID !== '' ? installBase.locationID : null,
    roomID: installBase.roomID && installBase.roomID !== '' ? installBase.roomID : null,
    workOrderID: installBase.workOrderID && installBase.workOrderID !== '' ? installBase.workOrderID : null,
    lastQuarterlyMaintenanceDate:
        installBase.lastQuarterlyMaintenanceDate === '' ? null : installBase.lastQuarterlyMaintenanceDate,
    lastSixMonthMaintenanceDate:
        installBase.lastSixMonthMaintenanceDate === '' ? null : installBase.lastSixMonthMaintenanceDate,
    lastYearlyMaintenanceDate:
        installBase.lastYearlyMaintenanceDate === '' ? null : installBase.lastYearlyMaintenanceDate,
    lastTwoYearMaintenanceDate:
        installBase.lastTwoYearMaintenanceDate === '' ? null : installBase.lastTwoYearMaintenanceDate,
    lastThreeYearMaintenanceDate:
        installBase.lastThreeYearMaintenanceDate === '' ? null : installBase.lastThreeYearMaintenanceDate,
    lastFiveYearMaintenanceDate:
        installBase.lastFiveYearMaintenanceDate === '' ? null : installBase.lastFiveYearMaintenanceDate,
    quantity: cleanQuantity(installBase.quantity),
    installDate: installBase.installDate === '' ? null : installBase.installDate
});

export function updateVisibleProducts(allProducts?: { [key: string]: Iproduct }): ThunkResult<any> {
    return (dispatch, getState) => {
        // to load products for all the facilities
        const { installBasesByID } = getState().manageInventory;

        // raises event for installbase products
        // triggers GET_ALL_PRODUCTS_SUCCESS for installbase products
        const installBasesArray = Object.values(installBasesByID);
        offlineProductsProvider.addNewProductsFromInstallBases(installBasesArray);

        // basically will set the latest date queried for products
        if (!allProducts) offlineProductsProvider.getAllProducts().then(products => handleFilteredProducts(products));
        else handleFilteredProducts(allProducts);

        return Promise.resolve(true);
    };
}

function handleFilteredProducts(allProducts: { [key: string]: Iproduct }) {
    if (Object.keys(allProducts).length > 0) {
        const mostRecentDate = getMostRecentDate(Object.values(allProducts));
        if (mostRecentDate) {
            localforage.setItem('med-gas-mobile_all-products_delta', mostRecentDate); // save this with it's own key so it won't get wiped on log out
        }
    }
}

function getMostRecentDate(objects: Iproduct[]): Date | null {
    let mostRecentDate: Date | null = null;

    objects.forEach(({ createDate, updateDate }) => {
        const mostRecentForCurrent =
            createDate && updateDate ? (createDate > updateDate ? createDate : updateDate) : createDate || updateDate;

        if (mostRecentForCurrent && (!mostRecentDate || mostRecentForCurrent > mostRecentDate)) {
            mostRecentDate = mostRecentForCurrent;
        }
    });

    return mostRecentDate;
}

/*
 *  For offline we retrieve all the products from persistant storage, filter it, then bring only
 * the filtered products into Redux.  Otherwise there are too many and it crashes redux dev tools.
 */
export function searchProducts(
    page: number,
    search: string,
    mainCategoryID: string,
    subcategoryID: string,
    brandID: string,
    origin: string,
    powerID: string,
    productTypeID: string,
    systemSizeID: string,
    isFinalProduct: boolean,
    productStandards: string[]
): ThunkResult<any> {
    return (dispatch, getState) => {
        // dispatch(beginAjaxCall());
        const searchString = search ? search.toLowerCase() : '';
        const { subcategories } = getState().productInfo;
        offlineProductsProvider
            .getAllProducts()
            .then(products => {
                const filteredProducts = filter(products, (prod: Iproduct) => {
                    let shouldInclude = true;
                    if (!prod.name) {
                        shouldInclude = false;
                        return false; // return because the other filters will fail
                    }
                    const subcategory = subcategories[prod.subcategoryID];
                    if (subcategoryID && prod.subcategoryID !== subcategoryID) {
                        shouldInclude = false;
                    }
                    if (mainCategoryID && subcategory.mainCategoryID !== mainCategoryID) {
                        shouldInclude = false;
                    }
                    const nameParts: string[] = [prod.name, prod.description];

                    if (prod?.sapMaterialNumber) {
                        nameParts.push(prod.sapMaterialNumber);
                    }

                    if (prod?.sku) {
                        nameParts.push(prod.sku);
                    }

                    const name = nameParts.join(': ');
                    if (searchString && searchString.length && name.toLowerCase().search(searchString) === -1) {
                        shouldInclude = false;
                    }

                    if (prod.mergedProductID && prod.mergedProductID.length) {
                        shouldInclude = false;
                    }
                    if (brandID && prod.brandID !== brandID) {
                        shouldInclude = false;
                    }
                    if (origin && prod.origin !== origin) {
                        shouldInclude = false;
                    }
                    if (powerID && prod.powerID !== powerID) {
                        shouldInclude = false;
                    }
                    if (productTypeID && prod.productTypeID !== productTypeID) {
                        shouldInclude = false;
                    }
                    if (systemSizeID && prod.systemSizeID !== systemSizeID) {
                        shouldInclude = false;
                    }
                    if (prod.isFinalProduct !== isFinalProduct) {
                        shouldInclude = false;
                    }
                    if (
                        productStandards &&
                        productStandards.length > 0 &&
                        !prod?.productStandards?.some(item => productStandards.includes(item.standardID))
                    ) {
                        shouldInclude = false;
                    }
                    return shouldInclude;
                });
                dispatch({
                    type: types.SEARCH_PRODUCTS,
                    products: filteredProducts.splice(0, constants.searchProductPageCount)
                });
            })
            .catch((error: any) => {
                constants.handleError(error, 'search products');
                console.error('[searchProducts]:', error);
            });
    };
}

export function getInventoryForJob(facilityID: string, jobID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.Inventory,
            status: AppSyncItemStatus.inProgress
        });

        let startTime: number;
        let lastLoaded = 0;
        let packetCount = 0;

        const url = API.inventory.getallinstallsforjob;
        const pagingType = 'None';

        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { jobID, facilityID, pagingType },
            onDownloadProgress: progressEvent => {
                const currentTime = new Date().getTime();
                if (!startTime) {
                    startTime = currentTime;
                }
                const timeElapsedInSeconds = (currentTime - startTime) / 1000;
                const loadedBits = progressEvent.loaded * 8 - lastLoaded;
                const speedInBps = loadedBits / timeElapsedInSeconds;
                const speedInMbps = speedInBps / 1_000_000;

                if (packetCount % 10 === 0) {
                    dispatch({ type: types.SET_DOWNLOAD_SPEED, downloadSpeed: speedInMbps.toFixed(2) });
                }

                packetCount++;
                lastLoaded = progressEvent.loaded;
            }
        };

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.Inventory,
                        status: AppSyncItemStatus.failed
                    });
                } else {
                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.Inventory });

                    dispatch({
                        type: types.GET_INVENTORY_SUCCESS,
                        installBases: data.data,
                        facilityID
                    });
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.GET_INVENTORY_FAILED });
                constants.handleError(error, 'get inventory', 'error', false);
                console.error('[getInventory]:', error);

                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.Inventory,
                    status: AppSyncItemStatus.failed
                });
            });
    };
}

export function getInventory(givenID?: string, skipQueue: boolean = false): ThunkResult<any> {
    return (dispatch, getState) => {
        let jobID = getState().manageJob.selectedJob.id;
        let facilityID = givenID ? givenID : getState().manageJob.selectedJob.facilityID;

        if (jobID === '' || facilityID === '') {
            console.error(`getInventory - jobID [${jobID}] or facilityID [${facilityID}] is empty`);
            return Promise.resolve(true);
        }

        const url = API.inventory.getallinstallsforjob;
        const pagingType = 'None';

        if (skipQueue) {
            const axiosOptions: AxiosRequestConfig = {
                method: 'get',
                params: { jobID, facilityID, pagingType }
            };

            return msalFetch(url, axiosOptions)
                .then((data: AxiosResponse<any>) => {
                    if (!data.data) {
                        throw new Error('error getting inventory');
                    } else {
                        dispatch({
                            type: types.GET_INVENTORY_SUCCESS,
                            installBases: data.data,
                            facilityID
                        });
                    }
                })
                .catch((error: any) => {
                    dispatch({ type: types.GET_INVENTORY_FAILED });
                    constants.handleError(error, 'get inventory', 'error', false);
                    console.error('[getInventory]:', error);
                    throw error;
                });
        } else {
            const axiosOptions: AxiosRequestConfig = {
                method: 'get',
                params: { jobID, facilityID, pagingType },
                url
            };

            dispatch({
                type: types.GET_INVENTORY,
                meta: {
                    offline: {
                        effect: { axiosOptions, message: 'Get Inventory for Facility' },
                        commit: {
                            type: types.GET_INVENTORY_SUCCESS,
                            facilityID
                        }
                    }
                }
            });

            return Promise.resolve(true);
        }
    };
}

export function getReportPreview(givenID?: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const jobID = givenID;
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'GET',
            params: {
                jobID
            }
        };

        const url = API.GET.report.preview;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                toastr.success('Running report preview.');
                dispatch(endAjaxCall());
            })
            .catch((error: any) => {
                dispatch(endAjaxCall());
                constants.handleError(error, 'get report preview');
                console.error('[getReportPreview]:', error);
                throw error;
            });
    };
}

/*
 ! This is not used currently in the offline app
* update the product in persistent storage and Redux if it is there.
*/
export function updateProduct(product: Iproduct): ThunkResult<any> {
    return (dispatch, getState) => {
        offlineProductsProvider
            .getAllProducts()
            .then((products: { [key: string]: Iproduct }) => {
                // dispatch({
                //   type: types.PRODUCT_UPDATE,
                //   product,
                //   meta: {
                //     offline: {
                //       effect: { axiosOptions, message: 'update product' },
                //       rollback: { type: types.PRODUCT_UPDATE, product: originalProduct } // TODO this rollback will not effect persistent storage
                //     }
                //   }
                // });
                const updatedProducts = mapValues(products, (prod: Iproduct) => {
                    if (prod.id === product.id) {
                        return { ...prod, ...product };
                    } else {
                        return prod;
                    }
                });
                return offlineProductsProvider.setAllProducts(updatedProducts);
            })
            .catch((error: any) => console.error('[updateProduct]:', error));
    };
}

export const updatePersistedProducts = (updatedProducts: Iproduct[]) => {
    return offlineProductsProvider
        .getAllProducts()
        .then((products: { [key: string]: Iproduct }) => {
            const keyedUpdatedProducts = keyBy(updatedProducts, 'id');
            return offlineProductsProvider.setAllProducts({
                ...products,
                ...keyedUpdatedProducts
            });
        })
        .then(() => {
            // let the inventory view know to update the view with new products.  Have to do this here because products are not in redux due to their size.
            const event = new CustomEvent('updatedProducts');
            document.dispatchEvent(event);
        });
};

/*
 * save (add) a new product to persistent storage only.  No need to update Redux because there are no installs associated with the product yet.
 */
export function saveProduct(product: Iproduct): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({ type: types.TOGGLE_MODAL_EDIT_PRODUCT });
        offlineProductsProvider
            .getAllProducts()
            .then((products: { [key: string]: Iproduct }) => {
                const updatedProducts = { ...products, [product.id]: product };
                offlineProductsProvider.setAllProducts(updatedProducts).then(() => {
                    toastr.success('Success', `Successfully created new product: ${product.name}`);
                });
            })
            .catch((error: any) => {
                constants.handleError(error, 'save product');
                console.error('[saveProduct]:', error);
            });

        const axiosOptions = {
            url: API.inventory.addproduct,
            method: 'post',
            data: product
        };
        dispatch({
            type: types.PRODUCT_ADD_SUCCESS,
            product,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'add new product' }
                    // rollback: { type: types.PRODUCT_ADD_SUCCESS, product: product }
                    // We could make a dummy reducer that dispatches an event that updates the product in offlineProductsProvider to be deleted
                    // Could extend rollback to receive a callback function (and call it)
                }
            }
        });
    };
}

export function updateInstall(install: IinstallBase, t: TFunction, fullObject = false): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({ type: types.CLOSE_ALL_MODALS });
        const originalInstallBase = getState().manageInventory.installBasesByID[install.id];
        const axiosOptions = {
            url: API.inventory.update,
            method: 'post',
            data: cleanInstallBase(install)
        };
        dispatch({
            type: types.INSTALL_UPDATE,
            install,
            fullObject,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'update install' },
                    rollback: {
                        type: types.INSTALL_UPDATE,
                        install: originalInstallBase
                    }
                }
            }
        });
        document.dispatchEvent(new CustomEvent('updatedInstallBases'));
    };
}

export function addOrUpdateFacilityContact(facilityContact: any, facilityID: string, isAdd: boolean): ThunkResult<any> {
    return dispatch => {
        console.log('updating', facilityContact);

        const { id } = facilityContact;
        const url = !id ? API.POST.contact.add : API.POST.contact.update;
        const method = !id ? 'POST' : 'PUT';

        if (!id) {
            const newId = uuidv4();
            facilityContact.id = newId;
            facilityContact.contactFacilities.forEach((cf: any) => (cf.contactID = newId));
        }

        const axiosOptions = {
            url,
            method,
            data: facilityContact
        };

        dispatch({
            type: isAdd ? types.ADD_FACILITY_CONTACT : types.UPDATE_FACILITY_CONTACT,
            payload: { ...facilityContact, facilityID },
            meta: {
                offline: {
                    effect: { axiosOptions }
                }
            }
        });
    };
}

export function deleteFacilityContact(facilityContact: any, facilityID: string): ThunkResult<any> {
    return dispatch => {
        const { id } = facilityContact;

        const axiosOptions = {
            url: API.POST.contact.delete(id),
            method: 'POST',
            params: FormUtil.toUrlSearchParams({ facilityId: facilityID })
        };

        dispatch({
            type: types.DELETE_FACILITY_CONTACT,
            payload: { facilityContactID: id, facilityID },
            meta: {
                offline: {
                    effect: { axiosOptions }
                }
            }
        });
    };
}

export function bulkUpdateInstalls(formValues: { [key: string]: any }): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({ type: types.CLOSE_ALL_MODALS });
        const { selection, installBasesByID } = getState().manageInventory;
        const selectedInstallBaseIDs = selection.map(item => {
            return item.split('select-')[1];
        });
        const installBasesToUpdate = filter(installBasesByID, install => {
            let shouldInclude = true;
            if (selectedInstallBaseIDs.indexOf(install.id) === -1) {
                shouldInclude = false;
            }
            if (install.isDeleted === true) {
                shouldInclude = false;
            }
            return shouldInclude;
        });

        // grab the inputs where the user actually filled something out
        const definedFormValues = pickBy(
            formValues,
            property => property !== null && property !== undefined && property && property.length !== 0
        );

        // in order to avoid moving an asset to a location where a room does not exist for instance, add back empty child location IDs that are not defined as part of the change
        if (definedFormValues.locationID && !definedFormValues.roomID) {
            definedFormValues.roomID = null;
        }
        if (definedFormValues.floorID && !definedFormValues.locationID) {
            definedFormValues.locationID = null;
        }
        if (definedFormValues.buildingID && !definedFormValues.floorID) {
            definedFormValues.floorID = null;
        }

        // update the installs
        // TODO when changing a parent location ID, need to reset the child location IDs
        const updatedInstalls = map(installBasesToUpdate, install => {
            // If any of these fields are an empty string, the backend will blow up
            let tempInstall: any = { ...install, ...definedFormValues };
            tempInstall.lastFiveYearMaintenanceDate =
                tempInstall.lastFiveYearMaintenanceDate === '' ? null : tempInstall.lastFiveYearMaintenanceDate;
            tempInstall.lastThreeYearMaintenanceDate =
                tempInstall.lastThreeYearMaintenanceDate === '' ? null : tempInstall.lastThreeYearMaintenanceDate;
            tempInstall.lastTwoYearMaintenanceDate =
                tempInstall.lastTwoYearMaintenanceDate === '' ? null : tempInstall.lastTwoYearMaintenanceDate;
            tempInstall.lastYearlyMaintenanceDate =
                tempInstall.lastYearlyMaintenanceDate === '' ? null : tempInstall.lastYearlyMaintenanceDate;
            tempInstall.lastSixMonthMaintenanceDate =
                tempInstall.lastSixMonthMaintenanceDate === '' ? null : tempInstall.lastSixMonthMaintenanceDate;
            tempInstall.lastQuarterlyMaintenanceDate =
                tempInstall.lastQuarterlyMaintenanceDate === '' ? null : tempInstall.lastQuarterlyMaintenanceDate;

            tempInstall.latestAGSMeasurementPointListResultID =
                tempInstall.latestAGSMeasurementPointListResultID === ''
                    ? null
                    : tempInstall.latestAGSMeasurementPointListResultID;
            tempInstall.latestAuditMeasurementPointListResultID =
                tempInstall.latestAuditMeasurementPointListResultID === ''
                    ? null
                    : tempInstall.latestAuditMeasurementPointListResultID;
            tempInstall.latestCommissioningMeasurementPointListResultID =
                tempInstall.latestCommissioningMeasurementPointListResultID === ''
                    ? null
                    : tempInstall.latestCommissioningMeasurementPointListResultID;
            tempInstall.latestMeasurementPointListResultID =
                tempInstall.latestMeasurementPointListResultID === ''
                    ? null
                    : tempInstall.latestMeasurementPointListResultID;
            tempInstall.latestVerificationMeasurementPointListResultID =
                tempInstall.latestVerificationMeasurementPointListResultID === ''
                    ? null
                    : tempInstall.latestVerificationMeasurementPointListResultID;

            tempInstall.buildingID = tempInstall.buildingID === '' ? null : tempInstall.buildingID;
            tempInstall.floorID = tempInstall.floorID === '' ? null : tempInstall.floorID;
            tempInstall.locationID = tempInstall.locationID === '' ? null : tempInstall.locationID;
            tempInstall.roomID = tempInstall.roomID === '' ? null : tempInstall.roomID;
            tempInstall.workOrderID = tempInstall.workOrderID === '' ? null : tempInstall.workOrderID;

            tempInstall.quantity = cleanQuantity(tempInstall.quantity);

            return tempInstall;
        });

        // we need to bulk delete the work orders if install is deleted
        let workOrdersForDeletedInstalls: IWorkOrder[] = [];
        let optimisticallyDeletedWorkOrders: IWorkOrder[] = [];

        if (updatedInstalls[0]?.isDeleted) {
            updatedInstalls.forEach(install => {
                workOrdersForDeletedInstalls = [
                    ...workOrdersForDeletedInstalls,
                    ...filter(getState().workOrder.workOrdersByID, wo => wo.installBaseID === install.id)
                ];
            });
            if (workOrdersForDeletedInstalls && workOrdersForDeletedInstalls.length > 0) {
                optimisticallyDeletedWorkOrders = map(workOrdersForDeletedInstalls, workOrder => ({
                    ...workOrder,
                    isDeleted: true
                }));
            }
        }

        const axiosOptions = {
            url: API.inventory.updateInstalls,
            method: 'put',
            data: updatedInstalls
        };
        dispatch({
            type: types.INSTALL_UPDATE_BULK,
            installs: updatedInstalls,
            workOrders: optimisticallyDeletedWorkOrders,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'update install' },
                    rollback: {
                        type: types.INSTALL_UPDATE_BULK,
                        installs: installBasesToUpdate,
                        workOrders: workOrdersForDeletedInstalls
                    }
                }
            }
        });
        document.dispatchEvent(new CustomEvent('updatedInstallBases'));
    };
}

/*
 * prepare for updating the product on the selected installs
 * get the installs
 * check that all the installs are the same category
 * pre-select the category
 * disable the category input
 */
export function startBulkUpdateInstallProduct(t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        const { selection, installBasesByID } = getState().manageInventory;
        const selectedInstallBaseIDs = selection.map(item => {
            return item.split('select-')[1];
        });
        const installBasesToUpdate = filter(installBasesByID, install => {
            let shouldInclude = true;
            if (selectedInstallBaseIDs.indexOf(install.id) === -1) {
                shouldInclude = false;
            }
            if (install.isDeleted === true) {
                shouldInclude = false;
            }
            return shouldInclude;
        });

        if (installBasesToUpdate.length === 0) {
            toastr.warning(t('toastMessage:warning'), t('toastMessage:mustSelectAtLeastOne'), constants.toastrWarning);
            return;
        }

        const firstCategory = selectMainCategoryFromProductID(getState(), {
            productID: installBasesToUpdate[0].productID
        });

        const validCategory =
            filter(installBasesToUpdate, install => {
                const category = selectMainCategoryFromProductID(getState(), {
                    productID: install.productID
                });
                return category.id !== firstCategory.id;
            }).length === 0;

        if (validCategory === false) {
            toastr.warning(
                t('toastMessage:warning'),
                t('toastMessage:mustSelectSameCategory'),
                constants.toastrWarning
            );
            return;
        }

        dispatch({
            type: types.SET_INSTALL_BATCH_CATEGORY,
            payload: firstCategory.id
        });
        dispatch({
            type: types.SET_INSTALL_BATCH_MODE,
            payload: true
        });
        dispatch({
            type: types.TOGGLE_MODAL_SEARCH_NEW_PRODUCTS
        });
    };
}

export const saveInstallsHelper = (
    installBases: { [key: string]: IinstallBase },
    t: TFunction,
    lastSync: number,
    product?: Iproduct,
    job?: Ijob,
    originalJobWorkOrders?: IjobWorkOrder[]
): ThunkResult<any> => {
    return dispatch => {
        dispatch(beginAjaxCall());

        let newWorkOrders: IWorkOrder[] = [];
        let newJobWorkOrders: IjobWorkOrder[] = [];
        const jobID = job ? job.id : '';

        // TODO make a helper function (isRepairFlow) to determine if we are in a repair flow
        if (
            job &&
            (job.jobTypeID === jobTypesIdEnum.repair ||
                job.jobTypeID === jobTypesIdEnum.maintenance ||
                job.jobTypeID === jobTypesIdEnum.warrantyBM ||
                job.jobTypeID === jobTypesIdEnum.servicePlan) &&
            originalJobWorkOrders
        ) {
            // Loop through each install
            Object.values(installBases).forEach(install => {
                const workOrderID = install.workOrderID ? install.workOrderID : uuidv4();
                installBases[install.id].workOrderID = workOrderID;

                const newWorkOrder: IWorkOrder = {
                    ...initialWorkOrder,
                    id: workOrderID,
                    priority: workOrderPrioritiesEnum.highRisk,
                    dueDate: moment.utc().format(constants.momentSQLFormat),
                    installBaseID: install.id
                };
                const newJobWorkOrder: IjobWorkOrder = {
                    jobID,
                    workOrderID,
                    isDeleted: false,
                    id: uuidv4(),
                    workOrder: newWorkOrder
                };

                installBases[install.id].jobWorkorderID = newJobWorkOrder.id;

                newWorkOrders.push(newWorkOrder);
                newJobWorkOrders.push(newJobWorkOrder);
            });
        }

        const installBasesForRollback = map(installBases, install => ({
            ...install,
            isDeleted: true
        }));

        const cleanInstalls = values(installBases).map(install => {
            let cleaned: any = cleanInstallBase(install);

            // Make sure quantity is a number
            if (cleaned.quantity === undefined || cleaned.quantity === null || Number.isNaN(cleaned.quantity)) {
                cleaned.quantity = 1;
            }

            if (typeof cleaned.quantity === 'string') {
                cleaned.quantity = parseInt(cleaned.quantity);
            }

            // Backend will freak out if you pass an empty string for these fields
            return omit(
                cleaned,
                'latestAGSMeasurementPointListResultID',
                'latestAuditMeasurementPointListResultID',
                'latestCommissioningMeasurementPointListResultID',
                'latestMeasurementPointListResultID',
                'latestVerificationMeasurementPointListResultID'
            );
        });
        const axiosOptions = {
            url: API.inventory.addinstall,
            method: 'post',
            data: { JobID: jobID, InstallBases: cleanInstalls }
        };

        dispatch({
            type: types.INSTALL_ADD,
            installs: installBases,
            product,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'add install' },
                    rollback: {
                        type: types.INSTALL_UPDATE_BULK,
                        installs: installBasesForRollback
                    }
                }
            }
        });

        // Save new work orders to the store
        if (newWorkOrders && newWorkOrders.length > 0) {
            newWorkOrders.forEach(wo => {
                dispatch({
                    type: types.ADD_WORKORDER,
                    workOrder: wo
                });
            });
        }

        // Save new job work orders to the store
        if (newJobWorkOrders && newJobWorkOrders.length > 0) {
            let jobWorkOrdersToSave: IjobWorkOrder[] = [...newJobWorkOrders];
            if (originalJobWorkOrders) {
                jobWorkOrdersToSave = [...originalJobWorkOrders, ...newJobWorkOrders];
            }

            dispatch({
                type: types.JOB_UPDATE_WORKORDERS,
                jobWorkOrders: jobWorkOrdersToSave
            });
        }

        // Since we are setting a timeout below, keep the spinning indicator going until the logic in the timeout is complete
        // Other wise the user will think the app is done loading only for it to kick off more stuff shortly after
        dispatch(beginAjaxCall());

        // Believe this timeout is here because we need to wait for these install bases to get created, before we try and refresh the app's data
        setTimeout(() => {
            // getLatestHelper will dispatch endAjaxCall() when it is done
            if (job) {
                dispatch(getWorkOrders(job.facilityID));
                dispatch(getLatestHelper(lastSync, job.id));
            } else {
                dispatch(endAjaxCall());
            }
        }, 500);

        toastr.success(t('toastMessage:success'), t('toastMessage:savedInstall'), constants.toastrSuccess);
        document.dispatchEvent(new CustomEvent('updatedInstallBases'));
    };
};

/*
 * save (add) an install (an install might have a quantity greater than 1 so we might be adding multiple installs)
 */
export function saveInstall(install: IinstallBase, t: TFunction, product?: Iproduct): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({ type: types.CLOSE_ALL_MODALS });

        // if quantity is greater than 1, we are creating multiple installs
        const newID = uuidv4();
        const job = getState().manageJob.selectedJob;
        const { lastSync } = getState().syncStatus;
        const originalJobWorkOrders = selectJobWorkOrdersForJobID(getState(), {
            jobID: job.id
        });
        let newInstalls = {};

        if (install.quantity && install.quantity > 1) {
            for (let i = install.quantity - 1; i >= 0; i--) {
                const newIDb = uuidv4();
                newInstalls = {
                    ...newInstalls,
                    [newIDb]: { ...install, id: newIDb, quantity: 1 }
                };
            }
        } else {
            newInstalls = { [newID]: { ...install, id: newID } };
        }
        dispatch(saveInstallsHelper(newInstalls, t, lastSync, product, job, originalJobWorkOrders));
    };
}

export function deleteInstall(installBaseID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const originalInstallBase = getState().manageInventory.installBasesByID[installBaseID];

        const workOrdersForDeletedInstall = filter(
            getState().workOrder.workOrdersByID,
            wo => wo.installBaseID === installBaseID
        );
        const optimisticallyDeletedWorkOrders = map(workOrdersForDeletedInstall, workOrder => ({
            ...workOrder,
            isDeleted: true
        }));
        dispatch({ type: types.CLOSE_ALL_MODALS });
        const axiosOptions = {
            url: API.inventory.deleteInstall,
            method: 'post',
            data: { id: installBaseID }
        };
        dispatch({
            type: types.INSTALL_DELETE,
            installBaseID,
            workOrders: optimisticallyDeletedWorkOrders,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'delete install' },
                    rollback: {
                        type: types.INSTALL_UPDATE,
                        install: { ...originalInstallBase, isDeleted: false },
                        workOrders: workOrdersForDeletedInstall
                    }
                }
            }
        });
        document.dispatchEvent(new CustomEvent('updatedInstallBases'));
    };
}

export function getCommissioningInstallBases(jobID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const url = `${API.job.getinstallbases}/${jobID}`;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            url
        };

        dispatch({
            type: types.GET_COMMISSIONING_INSTALL_BASES,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get Commissioning Install Bases' },
                    commit: {
                        type: types.GET_COMMISSIONING_INSTALL_BASES_SUCCESS
                    }
                }
            }
        });
    };
}

/*
 *   This is the same as addCommissioningInstallBases, except it accepts the exact values instead of looking up IDs in the store
 *   Don't want to break other functionality by updating the other function
 */
export const addCommissioningInstallBasesToJob = (jobId: string, installBaseIds: string[]): ThunkResult<any> => (
    dispatch,
    getState
) => {
    const data: JobNewInstallBases = {
        jobInstallBases: installBaseIds.map(installId => ({
            jobID: jobId,
            installbaseID: installId
        }))
    };
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data
    };
    const url = API.job.addinstallbases;

    return msalFetch(url, axiosOptions)
        .then((response: AxiosResponse<any>) => {
            if (!response.data) {
                throw new Error('missing data');
            } else {
                dispatch({
                    type: types.ADD_COMMISSIONING_INSTALL_BASES,
                    installBases: response.data.jobInstallBases
                });
                toastr.success('Success', 'Saved Assets to Job', constants.toastrSuccess);

                return response;
            }
        })
        .catch((error: any) => {
            dispatch({
                type: types.ADD_COMMISSIONING_INSTALL_BASES_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'add commissioning install bases');
            console.error('[addCommissioningInstallBases]:', error);
        });
};

export const addCommissioningInstallBases = (job: Ijob): ThunkResult<any> => (dispatch, getState) => {
    const selectedInstallBaseIDs = selectSelectedInstallBaseIDs(getState());
    const data: JobNewInstallBases = {
        jobInstallBases: selectedInstallBaseIDs.map(id => ({
            jobID: job.id,
            installbaseID: id
        }))
    };
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data
    };
    const url = API.job.addinstallbases;

    return msalFetch(url, axiosOptions)
        .then((response: AxiosResponse<any>) => {
            if (!response.data) {
                throw new Error('missing data');
            } else {
                dispatch({
                    type: types.ADD_COMMISSIONING_INSTALL_BASES,
                    installBases: response.data.jobInstallBases
                });
                toastr.success('Success', 'Saved Assets to Job', constants.toastrSuccess);

                return response;
            }
        })
        .catch((error: any) => {
            dispatch({
                type: types.ADD_COMMISSIONING_INSTALL_BASES_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'add commissioning install bases');
            console.error('[addCommissioningInstallBases]:', error);
        });
};

export const saveCommissioningData = (data: IcommissioningDataPayload): ThunkResult<any> => (dispatch, getState) => {
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data
    };
    const url = API.POST.commissioningForm;

    return msalFetch(url, axiosOptions)
        .then((response: AxiosResponse<IcommissioningData>) => {
            if (!response.data) {
                throw new Error('missing data');
            } else {
                dispatch({
                    type: types.SAVE_COMMISSIONING_DATA,
                    commissioningData: response.data
                });
                toastr.success('Success', 'Saved Commissioning Data', constants.toastrSuccess);

                return;
            }
        })
        .catch((error: any) => {
            dispatch({
                type: types.SAVE_COMMISSIONING_DATA,
                error,
                axiosOptions
            });
            constants.handleError(error, 'save commissioning data');
            console.error('[saveCommissioningData]:', error);
        });
};

/*
 * logic taken from 'getAllProducts' event without using background worker
 * should only be called when we want to look for products after it's already been loaded once via login
 */
export const ensureLatestProducts = (): ThunkResult<any> => async dispatch => {
    let updateDate: string | undefined | null = await localforage.getItem('med-gas-mobile_all-products_delta');
    if (!updateDate) {
        console.log('ensureLatestProducts [med-gas-mobile_all-products_delta] not found');
        return Promise.resolve(true);
    }
    const axiosOptions: AxiosRequestConfig = {
        url: API.inventory.products,
        method: 'post',
        data: { updateDate }
    };
    try {
        const resp = await msalFetch(axiosOptions.url!, axiosOptions);
        const newProducts: { [key: string]: Iproduct } = resp.data.result ? resp.data.result : resp.data;
        // Did we recieve any new products?
        console.log('ensureLatestProducts [new]', Object.keys(newProducts || {}).length);
        if (Object.keys(newProducts || {}).length > 0) {
            // Get the existing products already in localforage (browser IndexedDB storage)
            const products = await localforage.getItem<{ [key: string]: Iproduct }>('med-gas-mobile_all-products');
            // add all keys from newProducts to back to products
            // new products will overwrite existing products with the same key
            const updatedProducts = { ...products, ...newProducts };
            offlineProductsProvider.setAllProducts(updatedProducts); // updates complete list of products in localforage
            //console.log('ensureLatestProducts [finish]', Object.keys(newProducts).length + ' new products added to localforage');
            dispatch(updateVisibleProducts());
            return Promise.resolve(true);
        }
    } catch (error) {
        constants.handleError(error, 'Failed to ensure latest products', 'error', true);
        console.error('[ensureLatestProducts]:', error);
    }
};

/*
 * installContact or contact support
 */
export function installContact(installBase: IinstallBase, message: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const axiosOptions = {
            url: API.inventory.installContact,
            method: 'post',
            data: {
                facilityID: installBase.facilityID,
                installBaseID: installBase.id,
                message,
                beaconContactType: beaconContactTypeEnum.fse
            }
        };
        dispatch({
            type: types.INSTALL_CONTACT,
            installBaseID: installBase.id,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'contact support' }
                    // does contacting support need rollback support?
                }
            }
        });
        toastr.success('Success', 'Successfully contacted support.', constants.toastrSuccess);
    };
}

export function setSelectedProductByID(id: string): ThunkResult<any> {
    return (dispatch, getState) => {
        return offlineProductsProvider.getAllProducts().then(products => {
            const foundProducts = filter(products, { id });

            dispatch({
                type: types.SET_SELECTED_PRODUCT,
                product: foundProducts[0] || initialProduct
            });
        });
    };
}

export const updateProductSearchFormValues = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH,
    formValues
});

export const setProductSearchFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH,
    formValues
});

export const updateInstallFormValues = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_INVENTORY_INSTALL,
    formValues
});

export const setInstallFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_INVENTORY_INSTALL,
    formValues
});

export const updateJobDefaultsFormValue = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_EDIT_JOB_DEFAULTS,
    formValues
});

export const setJobDefaultsFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_EDIT_JOB_DEFAULTS,
    formValues
});

export const toggleAddHoursModal = () => ({
    type: types.TOGGLE_MODAL_ADD_HOURS
});

export const toggleEditProductModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_PRODUCT
});

export const toggleEditInstallModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_INSTALL
});

export const toggleEditJobDefaultsModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_JOB_DEFAULTS
});

export const toggleCommissioningDataFormModal = () => ({
    type: types.TOGGLE_MODAL_COMMISSIONING_DATA_FORM
});

export const toggleEditQuoteModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_QUOTE
});

export const toggleSearchNewProductsModal = () => ({
    type: types.TOGGLE_MODAL_SEARCH_NEW_PRODUCTS
});

export const toggleModalSignaturePad = () => ({
    type: types.TOGGLE_MODAL_SIGNATURE_PAD
});

export const toggleModalNotePad = () => ({
    type: types.TOGGLE_MODAL_NOTE_PAD
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_INVENTORY,
    filters
});

export const setFilterConfig = (config: FieldConfig) => ({
    type: types.ADD_FILTER_CONFIG,
    payload: config
});

export const clearTableFilter = () => ({
    type: types.CLEAR_TABLE_FILTER_MANAGE_INVENTORY
});

export const setSelectedProduct = (product?: { id: string }) => ({
    type: types.SET_SELECTED_PRODUCT,
    product
});

export const clearSelectedProductID = () => ({
    type: types.CLEAR_SELECTED_MANAGE_INVENTORY_PRODUCT_ID
});

export const addRecentProduct = (productID: string) => ({
    type: types.ADD_RECENT_PRODUCT,
    productID
});

export const removeRecentProduct = (productID: string) => ({
    type: types.REMOVE_RECENT_PRODUCT,
    productID
});

export const setEnableInstallBatchMode = (value: boolean) => ({
    type: types.SET_INSTALL_BATCH_MODE,
    payload: value
});

export const addVisibleProduct = (product: Iproduct) => ({
    type: types.PRODUCT_UPDATES,
    products: [product]
});

export const toggleModalAddFSE = () => ({
    type: types.TOGGLE_MODAL_ADD_FSE
});

export const toggleModalEditFacilityContacts = () => ({
    type: types.TOGGLE_MODAL_EDIT_FACILITY_CONTACTS
});

/*
 * Reset the products and switch to recent products
 */

export const resetNewProducts = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { recentProducts } = getState().manageInventory;
        offlineProductsProvider
            .getAllProducts()
            .then((products: { [key: string]: Iproduct }) => {
                const sortedProducts = keyBy(
                    recentProducts
                        .map(productID => {
                            const product = products[productID];
                            if (!product) {
                                console.error('[resetNewProducts]: did not find product in all products');
                            }
                            return product;
                        })
                        .filter(product => product),
                    'id'
                );
                dispatch({
                    type: types.NEW_PRODUCTS_RESET,
                    products: sortedProducts
                });
            })
            .catch((error: any) => console.error('[resetNewProducts]:', error));
    };
};

export const selectProductToAdd = (newProduct: Iproduct): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(setSelectedProduct(newProduct));
        dispatch(addRecentProduct(newProduct.id));
        dispatch(toggleEditInstallModal());
    };
};

export const updateInstallBaseSelection = (selection: string[]) => ({
    type: types.INSTALL_UPDATE_SELECTION,
    payload: selection
});

/*
 * product names are generated by: brand, category, subcategory, type, power, model, standard, brand, SKU
 */
export const createProductName = ({
    brandID,
    mainCategoryID,
    subcategoryID,
    productTypeID,
    powerID,
    systemSizeID,
    sku
}: {
    brandID: Ioption | undefined;
    mainCategoryID: Ioption | undefined;
    subcategoryID: Ioption | undefined;
    productTypeID: Ioption | undefined;
    powerID: Ioption | undefined;
    systemSizeID: Ioption | undefined;
    sku: string | undefined;
}) => {
    const category = mainCategoryID && mainCategoryID.label !== 'N/A' ? `${mainCategoryID.label}` : '';
    const subcategory = subcategoryID && subcategoryID.label !== 'N/A' ? `: ${subcategoryID.label}` : '';
    const productType = productTypeID && productTypeID.label !== 'N/A' ? `: ${productTypeID.label}` : '';
    const power = powerID && powerID.label !== 'N/A' ? `: ${powerID.label}` : '';
    const systemSize = systemSizeID && systemSizeID.label !== 'N/A' ? `: ${systemSizeID.label}` : '';
    const skuString = sku && sku.length ? `: ${sku}` : '';
    const brand = brandID && brandID.label !== 'N/A' ? `: ${brandID.label}` : '';
    const name = `${category}${subcategory}${productType}${power}${systemSize}${brand}${skuString}`;
    if (name.length > 250) {
        return name.substr(0, 250);
    } else {
        return name;
    }
};
